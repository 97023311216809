@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Headline";

#{$selector}

 {
    margin: 0;

    &--is-highlighted {
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &--light {
        &--inherit {
            color: $light--base;

            &--highlighted {
                @include gradientText(light, inherit);
                -webkit-filter: drop-shadow($elevation--text);
                filter: drop-shadow($elevation--text);
            }
        }

        &--primary {
            color: $light--primary;

            &--highlighted {
                @include gradientText(light, primary);
                -webkit-filter: drop-shadow($elevation--text--primary);
                filter: drop-shadow($elevation--text--primary);
            }
        }

        &--secondary {
            color: $light--secondary;

            &--highlighted {
                @include gradientText(light, secondary);
                -webkit-filter: drop-shadow($elevation--text--secondary);
                filter: drop-shadow($elevation--text--secondary);
            }
        }

        &--teritary {
            color: $light--teritary;

            &--highlighted {
                @include gradientText(light, teritary);
                -webkit-filter: drop-shadow($elevation--text--teritary);
                filter: drop-shadow($elevation--text--teritary);
            }
        }

        &--success {
            color: $light--success;
        }

        &--info {
            color: $light--gray;
        }

        &--warning {
            color: $light--warning;
        }

        &--error {
            color: $light--error;
        }

        &--white {
            color: $white;

            &--highlighted {
                @include gradientText(light, white);
                -webkit-filter: drop-shadow($elevation--text--white);
                filter: drop-shadow($elevation--text--white);
            }
        }
    }

    &--dark {
        &--inherit {
            color: $dark--base;

            &--highlighted {
                @include gradientText(dark, inherit);
                -webkit-filter: drop-shadow($elevation--text--dark);
                filter: drop-shadow($elevation--text--dark);
            }
        }

        &--primary {
            color: $dark--primary;

            &--highlighted {
                @include gradientText(dark, primary);
                -webkit-filter: drop-shadow($elevation--text--primary--dark);
                filter: drop-shadow($elevation--text--primary--dark);
            }
        }

        &--secondary {
            color: $dark--secondary;

            &--highlighted {
                @include gradientText(dark, secondary);
                -webkit-filter: drop-shadow($elevation--text--secondary--dark);
                filter: drop-shadow($elevation--text--secondary--dark);
            }
        }

        &--teritary {
            color: $dark--teritary;

            &--highlighted {
                @include gradientText(dark, teritary);
                -webkit-filter: drop-shadow($elevation--text--teritary--dark);
                filter: drop-shadow($elevation--text--teritary--dark);
            }
        }

        &--success {
            color: $dark--success;
        }

        &--info {
            color: $dark--gray;
        }

        &--warning {
            color: $dark--warning;
        }

        &--error {
            color: $dark--error;
        }

        &--white {
            color: $white;

            &--highlighted {
                @include gradientText(dark, white);
                -webkit-filter: drop-shadow($elevation--text--white--dark);
                filter: drop-shadow($elevation--text--white--dark);
            }
        }
    }

    &--xs {
        @include text('headline/xs');
    }

    &--sm {
        @include text('headline/sm');
    }

    &--md {
        @include text('headline/md');
    }

    &--lg {
        @include text('headline/lg');
    }

    &--xl {
        @include text('headline/xl');
    }

    &--xxl {
        @include text('headline/2xl');
    }

    &--center {
        text-align: center;
    }

    &--right {
        text-align: right;
    }
}
