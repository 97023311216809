@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".CarouselScreenshots";

#{$selector} {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $space-xl 0;

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: -150px;
        width: 100px;
        height: 100%;
        z-index: 1;

        @include screenLargerThan($screen-md) {
            left: -100px;
        }
    }

    &::after {
        left: auto;
        right: -150px;

        @include screenLargerThan($screen-md) {
            right: -100px;
        }
    }

    $overlayBoxShadow: 50px 0px 40px 40px;
    &--light {
        &:before,
        &:after {
            background-color: $light--background;
        }
        &:before {
            box-shadow: $overlayBoxShadow rgba($light--background, 1);
        }
        &:after {
            box-shadow: -$overlayBoxShadow rgba($light--background, 1);
        }

        &--primary {
            &::before,
            &::after {
                background-color: $light--primary;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($light--primary, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($light--primary, 1);
            }
        }

        &--secondary {
            &::before,
            &::after {
                background-color: $light--secondary;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($light--secondary, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($light--secondary, 1);
            }
        }

        &--teritary {
            &::before,
            &::after {
                background-color: $light--teritary;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($light--teritary, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($light--teritary, 1);
            }
        }

        &--success {
            &::before,
            &::after {
                background-color: $light--success;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($light--success, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($light--success, 1);
            }
        }

        &--error {
            &::before,
            &::after {
                background-color: $light--error;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($light--error, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($light--error, 1);
            }
        }

        &--info {
            &::before,
            &::after {
                background-color: $light--gray;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($light--gray, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($light--gray, 1);
            }
        }

        &--warning {
            &::before,
            &::after {
                background-color: $light--warning;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($light--warning, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($light--warning, 1);
            }
        }

        &--white {
            &::before,
            &::after {
                background-color: $light--backgroundWhite;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($light--backgroundWhite, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($light--backgroundWhite, 1);
            }
        }
    }

    &--dark {
        &::before,
        &::after {
            background-color: $dark--background;
        }
        &::before {
            box-shadow: $overlayBoxShadow rgba($dark--background, 1);
        }
        &::after {
            box-shadow: -$overlayBoxShadow rgba($dark--background, 1);
        }

        &--primary {
            &::before,
            &::after {
                background-color: $dark--primary;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($dark--primary, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($dark--primary, 1);
            }
        }

        &--secondary {
            &::before,
            &::after {
                background-color: $dark--secondary;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($dark--secondary, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($dark--secondary, 1);
            }
        }

        &--teritary {
            &::before,
            &::after {
                background-color: $dark--teritary;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($dark--teritary, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($dark--teritary, 1);
            }
        }

        &--success {
            &::before,
            &::after {
                background-color: $dark--success;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($dark--success, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($dark--success, 1);
            }
        }

        &--error {
            &::before,
            &::after {
                background-color: $dark--error;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($dark--error, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($dark--error, 1);
            }
        }

        &--info {
            &::before,
            &::after {
                background-color: $dark--gray;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($dark--gray, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($dark--gray, 1);
            }
        }

        &--warning {
            &::before,
            &::after {
                background-color: $dark--warning;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($dark--warning, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($dark--warning, 1);
            }
        }

        &--white {
            &::before,
            &::after {
                background-color: $dark--backgroundWhite;
            }
            &::before {
                box-shadow: $overlayBoxShadow rgba($dark--backgroundWhite, 1);
            }
            &::after {
                box-shadow: -$overlayBoxShadow rgba($dark--backgroundWhite, 1);
            }
        }
    }

    &__screenshot {
        object-fit: contain;
        pointer-events: none;
        box-shadow: $elevation--carousel;
        border-radius: 20px;

        &--dark {
            box-shadow: $elevation--carousel--dark;
        }

        &__wrapper {
            text-align: center;
        }
    }
}
